
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup() {
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const passwordFormDisplay = ref(false);

    const validatePasswordFields = Yup.object().shape({
      password: Yup.string().required().label("Current password"),
      newPassword: Yup.string().min(8).required().label("Password"),
      confirmPassword: Yup.string()
        .min(8)
        .required()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .label("Password Confirmation")
    });

    const createNewPassword = (values) => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updatePasswordButton.value?.removeAttribute("data-kt-indicator");
          //Fetch individual client profile personal infomation
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.patch(variables.CHANGE_PASSWORD, values)
              .then(() => {
                variables.toastAlert(
                  "Password is successfully changed!",
                  "success"
                );
                passwordFormDisplay.value = false;
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageTitle("Change Password");
    });

    return {
      passwordFormDisplay,
      validatePasswordFields,
      updatePasswordButton,
      createNewPassword
    };
  },
  created() {
    //Set page title
    document.title = "Change Password | " + process.env.VUE_APP_TITLE;
  }
});
